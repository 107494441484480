import { http } from '@/store/api/http-common';

const saveTicket = async (params) => {
	try {
		const { data } = await http.post('tickets', params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const addUserConfirm = async (params) => {
	try {
		const { data } = await http.post('tickets/add-user-confirm', params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const addUserTicket = async (params) => {
	try {
		const { data } = await http.post('tickets/add-additional-user', params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const reassignResponsible = async (id,params) => {
	try{
		const { data } = await http.put(`tickets/reassign-responsible/${id}`, params);
		return data || null;
	}catch(error){
		return Promise.reject(error);
	}
}

const getTicketById = async (id) => {
	try {
		const { data } = await http.get(`tickets/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const sendTicketCloseNotification = async (id) => {
	try {
		const { data } = await http.post(`tickets/ticket-notification/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getTicket = async (params) => {
	try {
		const { data } = await http.get('tickets', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getTicketsForUsers = async (params) => {
	try {
		const { data } = await http.get('tickets/get-my-tickets', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getTicketsForResponsibles = async (params) => {
	try {
		const { data } = await http.get('tickets/get-subtype-tickets', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const closeTicket = async (id) => {
	try {
		const { data } = await http.put(`tickets/close-ticket/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const confirmTicket = async (id) => {
	try {
		const { data } = await http.put(`tickets/confirm-ticket/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	saveTicket,
	getTicket,
	getTicketById,
	closeTicket,
	getTicketsForUsers,
	getTicketsForResponsibles,
	reassignResponsible,
	confirmTicket,
	addUserConfirm,
	addUserTicket,
	sendTicketCloseNotification
};
