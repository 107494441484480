import * as securityService from './service/security.service';
import textTransform from '@/helpers/textTransform.js';

const state = {
	user: null,
	usersActive: [],
	managements: [],
	costCenters: [],
	isPendingUsers: false,
	searchUsers:[]
};

const mutations = {
	SET_USER(state, payload) {
		state.user = payload;
	},
	SET_USERS_ACTIVE(state, payload) {
		state.usersActive = [...payload];
	},
	SET_MANAGEMENTS(state, payload) {
		state.managements = Array.isArray(payload) ? [...payload] : [];
	},
	SET_COST_CENTERS(state, payload) {
		state.costCenters = [...payload];
	},
	SET_IS_PENDING_USERS(state, payload) {
		state.isPendingUsers = payload;
	},
	SET_SEARCH_USERS(state, payload) {
		state.searchUsers = payload;
	},
};

const actions = {
	async getUsersActive({ state, commit }, data) {
		try {
			if (!state.isPendingUsers) {
				commit('SET_IS_PENDING_USERS', true);
				commit('SET_USERS_ACTIVE', []);
				const response = await securityService.usersActive(data);

				let users = response?.data;
				if (Array.isArray(users)) {
					users = users.map((user) => {
						return {
							...user,
							fullName: textTransform(user.fullName, 'capitalize'),
						};
					});
				}
				commit('SET_USERS_ACTIVE', users || []);
				return { ok: true, response };
			}
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		} finally {
			commit('SET_IS_PENDING_USERS', false);
		}
	},

	async getUser({ commit }, data) {
		try {
			commit('SET_USER', null);
			const response = await securityService.usersActive(data);

			let users = response?.data;
			let user = null;
			if (Array.isArray(users)) {
				users = users.map((user) => {
					return {
						...user,
						fullName: textTransform(user.fullName, 'capitalize'),
					};
				});
				user = users[0];
			}
			commit('SET_USER', user);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getManagements({ commit }, data) {
		try {
			const { user_id } = data;
			if (user_id) {
			
				let costCenters = [];
				const response = await securityService.getManagements(data);
				const managements = response?.data?.managements;
				managements.forEach((management) => {
					management.costCenters.forEach((ceco) => {
						if (!costCenters.some((item) => item.id == ceco.id)) {
							costCenters.push({
								...ceco,
								management_id: management?.id,
								name: `${ceco.name} - ${ceco.pep_code}`,
							});
						}
					});
				});
				commit('SET_MANAGEMENTS', managements);
				commit('SET_COST_CENTERS', costCenters);
				return { ok: true, response: response?.data };
			}
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getCostCenters({ commit }, data) {
		try {
			const response = await securityService.getCostCenters(data);
			commit('SET_COST_CENTERS', response.data);
			return { ok: true, response: response?.data };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async findUsers({ commit }, data) {
		try {
			const response = await securityService.searchUsers(data);
			let users = response?.data || [];
			users = users.map(user => ({
				...user,
				fullName: user.fullName?.toUpperCase() || '',
			}));
	
			commit('SET_SEARCH_USERS', users);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	cleanManagements({ commit }) {
		commit('SET_MANAGEMENTS', []);
		commit('SET_COST_CENTERS', []);
	},

	cleanUser({ commit }) {
		commit('SET_USER', null);
	},
};

const getters = {
	getUsers(state) {
		let items = [];
		if (Array.isArray(state.usersActive)) {
			items = state.usersActive.map((user) => {
				return { ...user, name: user.fullName };
			});
		}
		return items || [];
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
