import { http } from '../../../api/http-common';

// USERS
const usersActive = async (params) => {
	try {
		const { data } = await http.get('user', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const searchUsers = async (params) => {
	try {
		const { data } = await http.get('user/find-users', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getManagements = async (params) => {
	try {
		const { data } = await http.get('cost-center/' + params?.user_id, {
			params: { app_id: parseInt(process.env.VUE_APP_ID) },
		});
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getCostCenters = async () => {
	try {
		const { data } = await http.get(`cost-center/all`);

		return data || null;
	} catch (error) {
		return Promise.reject(error)
	}
}

export { usersActive, getManagements, getCostCenters, searchUsers };
